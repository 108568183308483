

import axios from "@/plugins/axios.js"

const actions = {
  fetchTasks({ commit, state}, force = false) {
    if(!force && state.data.length) return
    return new Promise((resolve, reject) => {
      axios.get("/tasks")
        .then((response) => {
          if(response.data.success){
            commit('SET_TASKS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
}

const getters = {
}

const mutations = {
  SET_TASKS(state, data) {
    state.data = data
  },
  ADD_TASK(state, item){
    state.data.unshift(item);
  },
  UPDATE_TASK(state, item){
    const dataIndex = state.data.findIndex((f) => f.id == item.id)
    Object.assign(state.data[dataIndex], item)
  },
  DELETE_TASK(state, itemId) {
    const ItemIndex = state.data.findIndex((item) => item.id == itemId)
    state.data.splice(ItemIndex, 1)
  },
}

const state = {
  data: [],
}

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

