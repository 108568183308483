
import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

import moduleNotification from "./modules/notification.js"
import moduleCategory from "./modules/category.js"
import moduleProduct from "./modules/product.js"
import moduleOrder from './modules/order.js'
import moduleUser from './modules/user.js'
import moduleReview from './modules/review.js'
import moduleRequirement from './modules/requirement.js'
import modulePlan from './modules/plan.js'
import moduleAttribute from './modules/attribute.js'
import moduleTask from './modules/task.js'
import moduleChat from './modules/chat.js'

Vue.use(Vuex)

export default new Vuex.Store({
    state,
    actions,
    getters,
    mutations,
    modules: {
        notification: moduleNotification,
        orderList: moduleOrder,
        categoryList: moduleCategory,
        productList: moduleProduct,
        userList: moduleUser,
        reviewList: moduleReview,
        requirement: moduleRequirement,
        planList: modulePlan,
        attributeList: moduleAttribute,
        taskList: moduleTask,
        chat: moduleChat,
    },
    strict: process.env.NODE_ENV !== 'production'
})
