

import axios from "@/plugins/axios.js"

const actions = {
  fetchRequirements({ commit, state}, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.get(`/requirements?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          if(response.data.success){
            commit('SET_REQUIREMENTS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  }
}

const getters = {
}

const mutations = {
  SET_REQUIREMENTS(state, data){
    state.data = data;
  },
  ADD_REQUIREMENT(state, item){
    state.data.unshift(item);
  },
  UPDATE_REQUIREMENT(state, item){
    const dataIndex = state.data.findIndex((f) => f.id == item.id)
     Object.assign(state.data[dataIndex], item)
  },
  DELETE_REQUIREMENT(state, itemId){
    const ItemIndex = state.data.findIndex((item) => item.id == itemId)
    state.data.splice(ItemIndex, 1);
  },
}

const state = {
  data: [],
}

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

