

import axios from "@/plugins/axios.js"

const actions = {
  fetchAttributes({ commit, state}, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.get(`/attributes?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          if(response.data.success){
            commit('SET_ATTRIBUTES', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
}

const getters = {
  
}

const mutations = {
  SET_ATTRIBUTES(state, data){
    state.data = data
  },
  ADD_ATTRIBUTE(state, item){
    state.data.unshift(item);
  },
  UPDATE_ATTRIBUTE(state, item){
    const dataIndex = state.data.findIndex((p) => p.id == item.id)
    Object.assign(state.data[dataIndex], item)
  },
  DELETE_ATTRIBUTE(state, itemId){
    const ItemIndex = state.data.findIndex((item) => item.id == itemId)
    state.data.splice(ItemIndex, 1);
  },
}

const state = {
  data: []
}

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

